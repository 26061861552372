import { TUser } from "../types/PlaybookTypes";
import { HTTPMethod } from "./HTTPMethod";
import { Maybe } from "../types/Types";
import axios from "axios";
import { getBaseAPIUrl, DEFAULT_TIMEOUT_IN_MS } from "../utils/apiUtils";
import { getUserIDTokenOrThrow } from "../utils/authUtils";
import { IPublicClientApplication } from "@azure/msal-browser";
import { getUserFromAPIResponse } from "@/types/APITypes";

export class OrganizationNotFoundError extends Error {
  constructor() {
    super("Organization not found");
    this.name = "OrganizationNotFoundError";
  }
}

export class OrganizationDisabledError extends Error {
  constructor() {
    super("Organization is disabled");
    this.name = "OrganizationDisabledError";
  }
}

export class OrganizationRequiresInviteError extends Error {
  constructor() {
    super("Organization requires an invite");
    this.name = "OrganizationRequiresInviteError";
  }
}

export async function createOrUpdateUser({
  idToken,
  inviteCode,
}: {
  idToken: string;
  inviteCode?: string;
}): Promise<TUser> {
  // Try to create the user
  try {
    const response = await axios.post(
      `${getBaseAPIUrl()}/users`,
      {
        id_token: idToken,
        ...(inviteCode ? { invite_code: inviteCode } : {}),
      },
      {
        headers: { "Content-Type": "application/json" },
        timeout: DEFAULT_TIMEOUT_IN_MS,
      },
    );

    return getUserFromAPIResponse(response.data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors?.at(0).code === "organization_not_found") {
      throw new OrganizationNotFoundError();
    } else if (axios.isAxiosError(error) && error.response?.data?.errors?.at(0).code === "organization_disabled") {
      throw new OrganizationDisabledError();
    } else if (
      axios.isAxiosError(error) &&
      error.response?.data?.errors?.at(0).code === "organization_requires_invite"
    ) {
      throw new OrganizationRequiresInviteError();
    } else {
      throw error;
    }
  }
}

export async function createOrRegisterUserAndOrg({
  idToken,
  accessToken,
}: {
  idToken: string;
  accessToken: string;
}): Promise<TUser> {
  try {
    // Try to create the organization
    await axios.post(
      `${getBaseAPIUrl()}/organizations`,
      { id_token: idToken, access_token: accessToken },
      {
        headers: { "Content-Type": "application/json" },
        timeout: DEFAULT_TIMEOUT_IN_MS,
      },
    );
  } catch (error) {
    // If the organization already exists, ignore the error. Otherwise rethrow
    if (!axios.isAxiosError(error) || error.response?.status !== 409) {
      throw error;
    }
  }

  const user = await createOrUpdateUser({ idToken });
  return user;
}

export async function fetchUserProfilePic({ accessToken }: { accessToken: string }): Promise<void> {
  try {
    const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.ok) {
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer]);

      // Convert the blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        localStorage?.setItem("profilePic", base64data as string);
      };
    } else if (response.status === 404) {
      // No profile picture
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

// Runs REST requests, logs errors, and returns a response
async function makeAuthenticatedRequest({
  msalInstance,
  organizationId,
  url,
  httpMethod,
  bodyStr,
  additionalHeaders = {},
}: {
  msalInstance: IPublicClientApplication;
  organizationId: Maybe<string>;
  httpMethod: HTTPMethod;
  url: string;
  bodyStr?: string;
  additionalHeaders?: Record<string, string>;
}): Promise<any> {
  // Fetch a token
  const token = await getUserIDTokenOrThrow(msalInstance);

  // Set the organization header
  if (organizationId) {
    additionalHeaders["Pincites-Organization"] = organizationId;
  }

  // Make the request
  const response = await axios({
    method: httpMethod,
    url: `${getBaseAPIUrl()}/${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...additionalHeaders,
    },
    data: bodyStr,
    timeout: DEFAULT_TIMEOUT_IN_MS,
  });

  return response.data || null;
}

export async function getFeedbackResponse(
  feedbackDetails: string,
  msalInstance: IPublicClientApplication,
): Promise<void> {
  try {
    await makeAuthenticatedRequest({
      msalInstance,
      organizationId: null, // Always give feedback from default org
      url: "feedback",
      httpMethod: HTTPMethod.POST,
      bodyStr: JSON.stringify({
        subject: "Feedback about Pincites admin dashboard",
        message: feedbackDetails,
      }),
    });
  } catch (error) {
    throw error;
  }
}
