import React from "react";
import { Tooltip, TooltipProps, makeStyles } from "@fluentui/react-components";
import { PincitesColor } from "./PincitesTheme";
import { SparkleIcon } from "./Icons";

const useStyles = makeStyles({
  sparkleIcon: {
    scale: 1.3,
    color: PincitesColor.BRAND_BLUE,
    ":hover": {
      color: PincitesColor.BRAND_BLUE_HOVER,
    },
  },
});

type PincitesTooltipProps = Partial<TooltipProps> & {
  content: string;
  children: React.JSX.Element;
};

export function PincitesTooltip({ content, children, ...tooltipProps }: PincitesTooltipProps): React.JSX.Element {
  return (
    <Tooltip withArrow content={content} relationship="description" {...tooltipProps}>
      {children}
    </Tooltip>
  );
}
export function AISparkleAndToolTip(): React.JSX.Element {
  const styles = useStyles();

  return (
    <PincitesTooltip content={"This content is used for your AI suggestions"}>
      <SparkleIcon className={styles.sparkleIcon} />
    </PincitesTooltip>
  );
}
