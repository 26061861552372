import React, { useEffect } from "react";
import { CheckmarkIcon, DismissIcon, ErrorIcon, InfoIcon } from "./Icons";
import { PincitesColor, PincitesShadow } from "./PincitesTheme";
import {
  PincitesFlexbox,
  PincitesFlexboxAlignItems,
  PincitesFlexboxGap,
  PincitesFlexboxJustifyContent,
} from "./PincitesFlexbox";
import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { Maybe } from "@/types/Types";

const useStyles = makeStyles({
  pincitesBanner: {
    paddingBlock: "4px",
    paddingInline: "32px",
    alignSelf: "flex-start",
    boxShadow: PincitesShadow.SHADOW4,
    color: PincitesColor.WHITE_ALWAYS,
    overflow: "hidden",
    marginBottom: "1rem",
  },
  hideBanner: {
    maxHeight: 0,
  },
  bannerInfo: {
    backgroundColor: PincitesColor.BRAND_BLUE_BACKGROUND_STATIC,
  },
  bannerSuccess: {
    backgroundColor: PincitesColor.GREEN_BACKGROUND,
  },
  bannerError: {
    backgroundColor: PincitesColor.RED_BACKGROUND,
  },
});

export enum PincitesBannerType {
  Info = "info",
  Success = "success",
  Error = "error",
}

export function PincitesBanner({
  content,
  rightContent,
  isShown,
  bannerType,
  onDismiss,
  isTemporary = true,
}: {
  content: React.ReactNode;
  isShown: boolean;
  bannerType: PincitesBannerType;
  onDismiss?: () => void;
  isTemporary?: boolean;
  rightContent?: React.ReactNode;
}): Maybe<React.JSX.Element> {
  const styles = useStyles();
  const bannerClass = isShown ? styles.pincitesBanner : styles.hideBanner;
  const bannerStyle = mergeClasses(
    bannerClass,
    getClassForBannerType(bannerType, styles), // Pass 'styles' here
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isShown && onDismiss && isTemporary) {
      // Check if isTemporary is true
      timer = setTimeout(() => {
        onDismiss();
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isShown, onDismiss, isTemporary]);

  if (!isShown) return null;

  return (
    <PincitesFlexbox
      alignItems={PincitesFlexboxAlignItems.CENTER}
      justifyContent={PincitesFlexboxJustifyContent.SPACE_BETWEEN}
      customClassName={bannerStyle}
    >
      <PincitesFlexbox alignItems={PincitesFlexboxAlignItems.CENTER} gap={PincitesFlexboxGap.GAP_4}>
        {IconForBannerType(bannerType)}
        <div className="inline-block">{content}</div>
      </PincitesFlexbox>

      {rightContent && <div style={{ float: "right", maxWidth: 300 }}>{rightContent}</div>}

      {onDismiss && <DismissIcon aria-label="dismiss" onClick={onDismiss} />}
    </PincitesFlexbox>
  );
}

function IconForBannerType(type: PincitesBannerType): React.JSX.Element {
  switch (type) {
    case PincitesBannerType.Success:
      return <CheckmarkIcon color="inherit" />;
    case PincitesBannerType.Error:
      return <ErrorIcon color="inherit" />;
    case PincitesBannerType.Info:
      return <InfoIcon color="inherit" />;
  }
}

function getClassForBannerType(type: PincitesBannerType, styles: ReturnType<typeof useStyles>): string {
  switch (type) {
    case PincitesBannerType.Success:
      return styles.bannerSuccess;
    case PincitesBannerType.Error:
      return styles.bannerError;
    case PincitesBannerType.Info:
      return styles.bannerInfo;
  }
}
