import { ButtonProps } from "@fluentui/react-components";

export enum PincitesButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SUBTLE = "SUBTLE",
  TRANSPARENT = "TRANSPARENT",
  DESTRUCTIVE = "DESTRUCTIVE",
}

export enum PincitesButtonSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export function getButtonSize(size: PincitesButtonSize): ButtonProps["size"] {
  switch (size) {
    case PincitesButtonSize.SMALL:
      return "small";
    case PincitesButtonSize.MEDIUM:
      return "medium";
    case PincitesButtonSize.LARGE:
      return "large";
  }
}

export function getAppearanceForButtonType(buttonType: PincitesButtonType): ButtonProps["appearance"] {
  switch (buttonType) {
    case PincitesButtonType.PRIMARY:
      return "primary";
    case PincitesButtonType.SECONDARY:
      return "secondary";
    case PincitesButtonType.SUBTLE:
      return "subtle";
    case PincitesButtonType.TRANSPARENT:
      return "transparent";
    default:
      return "secondary";
  }
}
