import axios, { AxiosResponse } from "axios";
import { Maybe } from "../types/Types";

export function getBaseAPIUrl(): string {
  const isLocal = process.env.NODE_ENV === "development";
  if (isLocal) {
    return "http://localhost:8080/api";
  } else {
    return process.env.NEXT_PUBLIC_API_BASE_URL || "https://api.pincites.com/api";
  }
}

export const DEFAULT_TIMEOUT_IN_MS = 30000;

export async function fetchWithToken(
  url: string,
  token: string,
  organizationId: Maybe<string>,
): Promise<AxiosResponse> {
  return requestWithToken({ method: "get", url, token, organizationId });
}

export async function deleteWithToken(
  url: string,
  token: string,
  organizationId: Maybe<string>,
): Promise<AxiosResponse> {
  return requestWithToken({ method: "delete", url, token, organizationId });
}

export async function putWithToken(
  url: string,
  token: string,
  organizationId: Maybe<string>,
  data: object,
): Promise<AxiosResponse> {
  return requestWithToken({ method: "put", url, token, organizationId, data });
}

export async function postWithToken(
  url: string,
  token: string,
  organizationId: Maybe<string>,
  data: object,
): Promise<AxiosResponse> {
  return requestWithToken({ method: "post", url, token, organizationId, data });
}

async function requestWithToken({
  method,
  url,
  token,
  organizationId,
  data,
}: {
  method: string;
  url: string;
  token: string;
  organizationId: Maybe<string>;
  data?: object;
}): Promise<AxiosResponse> {
  const additionalHeaders: Record<string, string> = {};
  if (organizationId) {
    additionalHeaders["Pincites-Organization"] = organizationId;
  }

  const isFormData = data instanceof FormData;
  const contentType = isFormData ? "multipart/form-data" : "application/json";

  return axios.request({
    method,
    url: `${getBaseAPIUrl()}/${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType,
      ...additionalHeaders,
    },
    timeout: DEFAULT_TIMEOUT_IN_MS,
    data,
  });
}
