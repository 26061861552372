import React from "react";
import { Badge, BadgeProps } from "@fluentui/react-components";

export enum PincitesBadgeColor {
  BRAND = "brand",
  DANGER = "danger",
  IMPORTANT = "important",
  INFORMATIVE = "informative",
  SEVERE = "severe",
  SUBTLE = "subtle",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum PincitesBadgeAppearance {
  FILLED = "filled",
  OUTLINE = "outline",
  GHOST = "ghost",
  TINT = "tint",
}

export enum PincitesBadgeSize {
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
}

export type PincitesBadgeProps = {
  appearance: PincitesBadgeAppearance;
  color: PincitesBadgeColor;
  text?: string;
  size?: PincitesBadgeSize;
};

export function PincitesBadge({
  appearance,
  color,
  text,
  size = PincitesBadgeSize.SMALL,
}: PincitesBadgeProps): React.JSX.Element {
  return (
    <Badge
      color={getColorFromPincitesBadgeColor(color)}
      appearance={getAppearanceFromPincitesBadgeAppearance(appearance)}
      size={getSizeFromPincitesBadgeSize(size)}
      id="pincites-badge"
    >
      {text && text}
    </Badge>
  );
}

function getAppearanceFromPincitesBadgeAppearance(badgeAppearance: PincitesBadgeAppearance): BadgeProps["appearance"] {
  switch (badgeAppearance) {
    case PincitesBadgeAppearance.FILLED:
      return "filled";
    case PincitesBadgeAppearance.OUTLINE:
      return "outline";
    case PincitesBadgeAppearance.GHOST:
      return "ghost";
    case PincitesBadgeAppearance.TINT:
      return "tint";
    default:
      return "filled";
  }
}

function getColorFromPincitesBadgeColor(badgeColor: PincitesBadgeColor): BadgeProps["color"] {
  switch (badgeColor) {
    case PincitesBadgeColor.BRAND:
      return "brand";
    case PincitesBadgeColor.DANGER:
      return "danger";
    case PincitesBadgeColor.IMPORTANT:
      return "important";
    case PincitesBadgeColor.INFORMATIVE:
      return "informative";
    case PincitesBadgeColor.SEVERE:
      return "severe";
    case PincitesBadgeColor.SUBTLE:
      return "subtle";
    case PincitesBadgeColor.SUCCESS:
      return "success";
    case PincitesBadgeColor.WARNING:
      return "warning";
    default:
      return "brand";
  }
}

function getSizeFromPincitesBadgeSize(badgeSize: PincitesBadgeSize): BadgeProps["size"] {
  switch (badgeSize) {
    case PincitesBadgeSize.EXTRA_SMALL:
      return "extra-small";
    case PincitesBadgeSize.SMALL:
    default:
      return "small";
  }
}
