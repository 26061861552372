import React, { ReactElement } from "react";
import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import {
  PincitesButtonSize,
  PincitesButtonType,
  getAppearanceForButtonType,
  getButtonSize,
} from "../../utils/buttonUtils";
import { Margin, getCSStylesFromMargins } from "./Margin";
import { PincitesTooltip } from "./PincitesTooltip";
import { PincitesSpinner, PincitesSpinnerAppearance, PincitesSpinnerSize } from "./PincitesSpinner";
import { PincitesColor } from "./PincitesTheme";
import { Maybe } from "../../types/Types";

const useStyles = makeStyles({
  destructiveButton: {
    // Apply common styles to various states
    "&:not(:disabled), &:active:hover, &[disabled], &[disabled]:hover, &[disabled]:active:hover": {
      backgroundColor: PincitesColor.RED_DANGER_BACKGROUND,
      color: PincitesColor.WHITE_ALWAYS,
      ...shorthands.borderColor(PincitesColor.WHITE_ALWAYS),
    },
    // Additional specific styles
    "&:not(:disabled)": {
      cursor: "pointer",
    },
    "&[disabled]": {
      cursor: "not-allowed",
    },
    "&:active:hover": {
      backgroundColor: PincitesColor.RED_ACTIVE,
    },
  },
});

export function PincitesButton({
  showSpinner = false,
  label,
  onClick,
  buttonType,
  icon,
  buttonSize = PincitesButtonSize.MEDIUM,
  isDisabled = false,
  ariaLabel,
  tooltipContent,
  href,
  margin = [],
}: {
  showSpinner?: boolean;
  label?: string;
  onClick?: () => void;
  buttonType: PincitesButtonType;
  icon?: ReactElement | null;
  buttonSize?: PincitesButtonSize;
  isDisabled?: boolean;
  href?: string;
  ariaLabel?: string;
  tooltipContent?: Maybe<string>;
  margin?: Margin[];
}): React.JSX.Element {
  const styles = useStyles();
  const onClickHandler = href ? () => window.open(href, "_blank") : onClick;

  const buttonProps = {
    onClick: onClickHandler,
    icon: icon || null,
    size: getButtonSize(buttonSize),
    appearance: getAppearanceForButtonType(buttonType),
    "aria-label": ariaLabel ?? label,
    disabled: isDisabled,
    style: getCSStylesFromMargins(margin),
    className: buttonType === PincitesButtonType.DESTRUCTIVE ? styles.destructiveButton : undefined,
  };

  const buttonContent = showSpinner ? (
    <PincitesSpinner
      id="button-spinner"
      size={PincitesSpinnerSize.TINY}
      appearance={
        buttonType === PincitesButtonType.DESTRUCTIVE
          ? PincitesSpinnerAppearance.INVERTED
          : PincitesSpinnerAppearance.PRIMARY
      }
    />
  ) : (
    // Capitalizes first letter of every word
    label && label
  );

  // Adds tooltip around button if one exists
  return tooltipContent ? (
    <PincitesTooltip content={tooltipContent}>
      <Button {...buttonProps}>{buttonContent}</Button>
    </PincitesTooltip>
  ) : (
    <Button {...buttonProps}>{buttonContent}</Button>
  );
}
